var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('iq-card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"new-user-info"},[_c('b-row',[_c('b-form-group',{staticClass:"col-md-12",attrs:{"label":"Title:","label-for":"title"}},[_c('ValidationProvider',{ref:"title",attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","placeholder":"enterprises Title"},model:{value:(_vm.enterprises.title),callback:function ($$v) {_vm.$set(_vm.enterprises, "title", $$v)},expression:"enterprises.title"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Link:","label-for":"arabic_title"}},[_c('ValidationProvider',{ref:"link",attrs:{"name":"link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","placeholder":"link"},model:{value:(_vm.enterprises.link),callback:function ($$v) {_vm.$set(_vm.enterprises, "link", $$v)},expression:"enterprises.link"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label-for":"exampleInputcolor","label":"enterprises color"}},[_c('ValidationProvider',{ref:"color",attrs:{"name":"Color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"color","id":"exampleInputcolor"},model:{value:(_vm.enterprises.color),callback:function ($$v) {_vm.$set(_vm.enterprises, "color", $$v)},expression:"enterprises.color"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-12",attrs:{"label":"Description","label-for":"enterprisesDesc"}},[_c('b-form-textarea',{attrs:{"id":"enterprisesDesc","rows":"2"},model:{value:(_vm.enterprises.description),callback:function ($$v) {_vm.$set(_vm.enterprises, "description", $$v)},expression:"enterprises.description"}})],1)],1),_c('div',{staticClass:"d-flex  mt-4 align-items-center justify-content-between"},[_c('b-form-checkbox',{model:{value:(_vm.enterprises.is_active),callback:function ($$v) {_vm.$set(_vm.enterprises, "is_active", $$v)},expression:"enterprises.is_active"}},[_vm._v("Is Active")]),(_vm.edit)?_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Edit enterprises")]):_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Add New enterprises")])],1)],1)]},proxy:true}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }